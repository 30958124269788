exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-industrial-service-js": () => import("./../../../src/pages/services/industrial-service.js" /* webpackChunkName: "component---src-pages-services-industrial-service-js" */),
  "component---src-pages-services-maintenance-service-js": () => import("./../../../src/pages/services/maintenance-service.js" /* webpackChunkName: "component---src-pages-services-maintenance-service-js" */),
  "component---src-pages-services-manpower-support-js": () => import("./../../../src/pages/services/manpower-support.js" /* webpackChunkName: "component---src-pages-services-manpower-support-js" */)
}

